import _ from 'lodash';
import { start, success, fail, apiAction } from '../middleware/fetch';
import { resetLoader } from './loading';
import { getSubscription } from './subscription';
import { analyticAction } from './analytic';
import { getUserId } from 'selectors/user';
import { CybersourceKey } from 'lib/cybersource/const';

export const PAYMENT_ONE_TIME_TOKEN = `payment/ONE_TIME_TOKEN`;
export const PAYMENT_INTENT = `payment/INTENT`;
export const PAYMENT_DETAILS_FETCH = `payment/DETAILS_FETCH`;
export const PAYMENT_DETAILS_DELETE = `payment/DETAILS_DELETE`;
export const PAYMENT_DETAILS_UPDATE = `payment/DETAILS_UPDATE`;

const initialState = {
  token: null,
  ok: null,
  paymentResult: null,
  paymentOfferId: null,
  sessionId: null,
};

// TODO: Move to utils
const getErrorCode = (error = {}) => {
  const { errors = [] } = error;
  const [firstError = {}] = errors;
  const { code } = firstError;
  return code;
};

// TODO: Move to utils
const isNoDetails = (code) => code === 'GET_PAYMENTS_NO_DETAILS';

const isValidToken = (token) => token && !token.error;

const paymentReducer = (state = initialState, action) => {
  const { type, payload, apiActionContext } = action;
  switch (type) {
    case start(PAYMENT_ONE_TIME_TOKEN): {
      return {
        ...state,
        ok: null,
        token: null,
        error: null,
        sessionId: null,
      };
    }
    case success(PAYMENT_ONE_TIME_TOKEN): {
      const { token, sessionId } = payload;
      return {
        ...state,
        token,
        sessionId,
      };
    }
    case start(PAYMENT_INTENT): {
      return {
        ...state,
        ok: null,
        error: null,
        paymentIntent: null,
        paymentResult: null,
        paymentOfferId: null,
      };
    }
    case success(PAYMENT_INTENT): {
      triggerSegmentAnalytics('Purchase Successful', action);
      const { apiActionContext } = action;
      const { url, options } = apiActionContext;
      const { payment } = payload;
      return {
        ...state,
        ok: true,
        token: null,
        paymentIntent: url,
        paymentResult: payment,
        paymentOfferId: options?.paymentOfferId,
        sessionId: null,
      };
    }
    case fail(PAYMENT_INTENT): {
      triggerSegmentAnalytics('Purchase Errored', action);
      const { error } = payload;
      return {
        ...state,
        ok: false,
        // TODO: Decide if we keep the token on fail or not...
        token: null,
        error,
        sessionId: null,
      };
    }
    case start(PAYMENT_DETAILS_FETCH): {
      const { keepErrorOk } = payload;
      return {
        ...state,
        ok: keepErrorOk ? state.ok : null,
        token: null,
        error: keepErrorOk ? state.error : null,
        details: null,
        sessionId: null,
      };
    }
    case success(PAYMENT_DETAILS_FETCH): {
      const { paymentDetails = [] } = payload || {};
      const details = paymentDetails.map((p) => ({
        ...p,
        cardExpirationDate: _.get(
          p,
          `paymentMethodSpecificParams.cardExpirationDate`,
        ),
        cardLastFourDigits: _.get(
          p,
          `paymentMethodSpecificParams.lastCardFourDigits`,
        ),
        cardBrand: _.get(p, `paymentMethodSpecificParams.variant`),
      }));
      return {
        ...state,
        details,
      };
    }
    case fail(PAYMENT_DETAILS_FETCH): {
      // Use case where error object does not wrap i.e. errors: [] instead of error: { errors: [] }
      // This occurs when an error is returned in 200 status response
      const error = _.get(payload, 'error', { ...payload });

      const code = getErrorCode(error);
      // Only report errors that are legitimate, user with no existing details are not considered
      // actual errors
      const errorData = !isNoDetails(code) && { error };
      const { details } = state;
      return {
        ...state,
        ...errorData,
        details: details || [],
      };
    }
    case start(PAYMENT_DETAILS_UPDATE): {
      return {
        ...state,
        ok: null,
        error: null,
      };
    }
    case success(PAYMENT_DETAILS_UPDATE): {
      const paymentDetailsId = _.get(payload, 'paymentDetails.id', '');
      const {
        options: {
          additionalParams: { items = [], orderId = '' },
        },
      } = apiActionContext;
      if (!_.isEmpty(items) && paymentDetailsId) {
        action.asyncDispatch(
          sendPaymentIntent({
            items,
            orderId,
            paymentDetailsId,
          }),
        );
      }
      return {
        ...state,
        ok: true,
      };
    }
    case fail(PAYMENT_DETAILS_DELETE): {
      return {
        ...state,
        ...payload,
      };
    }
    case fail(PAYMENT_DETAILS_UPDATE): {
      return {
        ...state,
        ...payload,
        ok: false,
      };
    }
    default:
      return state;
  }
};

const hasPaymentDetails = (payment) => {
  return payment.details && !_.isEmpty(payment.details);
};

const onOneTimeToken = () => {
  return {
    type: start(PAYMENT_ONE_TIME_TOKEN),
  };
};

const saveOneTimeToken = (token) => {
  return {
    type: success(PAYMENT_ONE_TIME_TOKEN),
    payload: token?.token ? { ...token } : { token },
  };
};

const sendPaymentIntent = ({
  token,
  items,
  orderId = '',
  paymentDetailsId = '',
  sessionId,
}) => (dispatch, getState) => {
  const osOffer = {};
  const parsedItems = items.map((item) => {
    const [offerId, osOfferId] = item.split('::');
    osOffer.id = osOfferId;
    return offerId;
  });
  const payload =
    token?.token?.source !== CybersourceKey
      ? {
          token,
          items: parsedItems,
          osOfferId: osOffer.id,
        }
      : {
          ...token,
          items: parsedItems,
          osOfferId: osOffer.id,
        };

  const { subscription, payment, user } = getState();
  const userId = getUserId(user);
  const currentSubscription = getSubscription(subscription);
  const paymentDetailId =
    paymentDetailsId || _.get(payment, 'details.0.id', '');

  const currentSubscriptionOfferId = _.get(
    currentSubscription,
    ['snapshotOffer', 'id'],
    _.get(currentSubscription, 'id', null),
  );

  let useApi = 'fe-api-newPlan';
  let path = undefined;

  if (orderId && orderId !== '') {
    _.set(payload, 'orderId', orderId);
  }

  // User has an active subscription
  if (currentSubscriptionOfferId) {
    useApi = 'fe-api-changePlan';
    _.set(payload, 'from', currentSubscriptionOfferId);

    if (paymentDetailId) {
      _.set(payload, 'paymentDetailsId', paymentDetailId);

      if (!token) {
        _.set(payload, 'token', paymentDetailId);
      }
    }

    path = `/users/${userId}/switch`;
  }

  // User has an active subscription
  if (currentSubscriptionOfferId) {
    _.set(payload, 'from', currentSubscriptionOfferId);
  }

  // Fingerprint sessionId
  if (sessionId) {
    _.set(payload, 'sessionId', sessionId);
  }

  dispatch(
    apiAction(PAYMENT_INTENT, useApi, {
      method: 'post',
      body: payload,
      paymentOfferId: subscription?.selections,
      ...(path && { path }),
    }),
  );
};

const triggerSegmentAnalytics = (eventName, action) => {
  const { apiActionContext, payload } = action;
  const offerId = _.get(apiActionContext, 'options.body.items[0]', '');
  const url = _.get(apiActionContext, 'url', '');
  const { code: errorCode = '', message: errorMessage = '' } = _.get(
    payload,
    'error.errors[0]',
    {},
  );
  let analyticObj = {
    eventName,
    id: offerId,
    name: offerId,
    ...(errorCode && { errorCode }),
    ...(errorMessage && { errorMessage }),
  };
  if (url === 'fe-api-newPlan') {
    action.asyncDispatch(
      analyticAction({
        ...analyticObj,
        type: 'payment',
        userType: 'premium',
      }),
    );
  } else if (url === 'fe-api-changePlan') {
    action.asyncDispatch(
      analyticAction({
        ...analyticObj,
        type: 'switch',
      }),
    );
  }
};

const getPaymentDetails = (keepErrorOk = false) => {
  return (dispatch) => {
    dispatch(
      resetLoader([
        PAYMENT_DETAILS_DELETE,
        PAYMENT_DETAILS_UPDATE,
        PAYMENT_INTENT,
      ]),
    );
    dispatch(
      apiAction(PAYMENT_DETAILS_FETCH, `fe-api-payments`, { keepErrorOk }),
    );
  };
};

const deletePaymentDetails = (paymentDetailsId) => {
  return apiAction(PAYMENT_DETAILS_DELETE, `fe-api-payments`, {
    method: `delete`,
    body: {
      paymentDetailsId,
    },
  });
};

const updatePaymentDetails = ({ paymentDetailsId, token, items, orderId }) => {
  const bodyPayload =
    token?.token?.source !== CybersourceKey
      ? {
          paymentDetailsId,
          token,
        }
      : { paymentDetailsId, ...token };
  return apiAction(PAYMENT_DETAILS_UPDATE, `fe-api-payments`, {
    method: `put`,
    body: bodyPayload,
    additionalParams: {
      items,
      orderId,
    },
  });
};

export {
  paymentReducer,
  isValidToken,
  hasPaymentDetails,
  onOneTimeToken,
  saveOneTimeToken,
  getPaymentDetails,
  deletePaymentDetails,
  sendPaymentIntent,
  updatePaymentDetails,
};

export default paymentReducer;
