import _ from 'lodash';
import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, withRouter } from 'react-router-dom';
import qs from 'qs';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';

import ScrollToTopRedirect from '../ui/ScrollToTopRedirect';

import NotificationStatusBar from '../ui/NotificationStatusBar';
import HelpSupportUI from '../ui/HelpSupport';

import HelpSupportButton from '../ui/HelpSupportButton';
import { getRouteList } from '../../utils/route';

import UnsupportedBrowser from '../../components/ui/UnsupportedBrowser';
import { getBrowser, supportBrowser } from '../../utils';

import defaultConfig from '../../constants/defaultConfig';

import RedirectLegacyURL from '../features/RedirectLegacyURL';
import UpdateDocumentTitle from '../ui/UpdateDocumentTitle';

import RouteWithGoogleAnalytics from './RouteWithGoogleAnalytics';
import { getConfigValue } from '../ui/new-home/utils';
import RouteWithReactHelmet from './RouteWithReactHelmet';
import UpdateMetaDescription from '../ui/UpdateMetaDescription';
import Helper from '../../components/helpers/Helper';
import { withFeatureFlags } from '../context/withFeatureFlags';
import { SearchContext } from '../search/SearchProvider';
import ChartBeatContainer from 'services/chartbeat/component/ChartBeatContainer';

import NewHomeContainer from '../ui/new-home/layouts/NewHomeContainer';
import FitnessLanding from 'components/views/FitnessLanding';
// const NewHomeContainer = loadable(() => import('../ui/new-home/layouts/NewHomeContainer'));
import { isFitnessSectionEnabled } from 'utils/getAssetData';
import { pathnameToEventMapper } from 'analytic/util';
import { dispatchAnalytic } from 'ducks/analytic';

const Epg = loadable(() => import('../views/Epg'));
const Voucher = loadable(() => import('../views/Voucher'));
const GetHelp = loadable(() => import('../views/GetHelp'));
const SamsungTV = loadable(() => import('../views/SamsungTV'));
const Advertise = loadable(() => import('../views/Advertise'));
const PairDeviceContainer = loadable(() =>
  import('../views/PairDeviceContainer'),
);
const CommercialVenues = loadable(() => import('../views/CommercialVenues'));
const PageHeader = loadable(() => import('./PageHeader'));
const PageFooter = loadable(() => import('./PageFooter'));

// This is a wrapper for RouteComponent down below which takes care of setting state upon route change
const RouteWrapper = ({
  children,
  route,
  setState,
  onPathnameChange,
  navigations,
  isNavigationsReady,
}) => {
  const { location } = window;

  useEffect(() => {
    const useOwnPageHeader = _.get(route, 'useOwnPageHeader');
    const useOwnMetadata = _.get(route, 'useOwnMetadata', false);
    const hideSaagBar = _.get(route, 'hideSaagBar', false);
    const useOwnPageFooter = _.get(route, 'useOwnPageFooter', false);
    onPathnameChange(location.pathname);

    setState({
      currentPath: location.pathname,
      useOwnPageHeader,
      useOwnPageFooter,
      useOwnMetadata,
      hideSaagBar,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, location.pathname]);

  useEffect(() => {
    if (isNavigationsReady) {
      const PageLoadEvent = pathnameToEventMapper({
        navigations,
        pathname: location.pathname,
      });
      PageLoadEvent.name !== '' && dispatchAnalytic(PageLoadEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigationsReady, location.pathname]);

  return children;
};

class PortalRoutes extends Component {
  static contextType = SearchContext;

  constructor(props, context) {
    super(props, context);

    this.footer = null;

    const routes = getRouteList(this.props.navigations, this.props);
    const showHelpSupport =
      this.props.location && this.props.location.pathname === '/helpsupport';
    const showHelpSupportNotification =
      this.props.location && this.props.location.pathname === '/support';
    const browserData = getBrowser();

    this.state = {
      routes: routes,
      isFooterVisible: false,
      showHelpSupport,
      unSupported: !supportBrowser(browserData),
      showNotification: showHelpSupportNotification,
      browserData,
      isNavigationsReady: false,
    };

    this.addHandlers = this.addHandlers.bind(this);
    this.removeHandlers = this.removeHandlers.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    this.addHandlers();
  }

  componentWillUnmount() {
    this.footer = null;
    this.removeHandlers();
  }

  componentDidUpdate(prevProps) {
    const { navigations } = this.props;
    if (prevProps.navigations !== navigations) {
      const routes = getRouteList(navigations, this.props);
      this.setState({ routes });

      const { isNavigationsReady } = this.state;
      if (!isNavigationsReady && navigations.length) {
        // !isNavigationsReady : to prevent isNavigationsReady being updated unnecessarily
        // navigations.length : checking if navigations is not empty because sometimes this code executed while navigations is empty
        this.setState({ isNavigationsReady: true });
      }
    }

    this.onScroll();
  }

  addHandlers() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
  }

  removeHandlers() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
  }

  clickHandler = () => {
    this.setState({ showHelpSupport: true });
  };

  onScroll() {
    if (this.footer) {
      const elRect = this.footer.getBoundingClientRect();
      const inside = elRect.top < window.innerHeight;

      if (this.state.isFooterVisible !== inside) {
        this.setState({ isFooterVisible: inside });
      }
    }
  }

  render() {
    // TODO: do some sort of filtering here to handle not found pages
    const routes = this.state.routes;
    const loginRoute = routes.find((item) => item.name === 'Login');
    const loginPath = _.get(loginRoute, 'path', '/');
    const { user, setDevice, newHome, theme, getFeatureFlags } = this.props;
    const { location } = window;
    //const { user } = this.props;
    const { unSupported, browserData, isNavigationsReady } = this.state;
    const browserName = _.get(browserData, 'name');
    const browserVersion = _.get(browserData, 'version');
    const isArticlePage =
      location && location.pathname && location.pathname.includes('/news');

    if (unSupported) {
      return (
        <UnsupportedBrowser
          browserName={browserName}
          browserVersion={browserVersion}
        />
      );
    }

    let pageFooter = !this.state.useOwnPageFooter && (
      <PageFooter
        id='footer'
        key='footer'
        ref={(pageFooterNode) =>
          (this.footer = ReactDOM.findDOMNode(pageFooterNode))
        }
        className={`${this.state.showHelpSupport ? 'blur-element' : ''}`}
        newHome={newHome}
      />
    );
    if (newHome) {
      pageFooter = (
        <NewHomeContainer footerwidth={`max-w-full`} footerPadding={`px-0`}>
          {pageFooter}
        </NewHomeContainer>
      );
    }
    // Custom pages/routes that should not conform to the normal page structure
    const pathName = _.get(location, 'pathname');
    switch (pathName) {
      case '/connect':
        if (getFeatureFlags && getFeatureFlags('FEATURE_CONNECT_PAGE')) {
          return (
            <RedirectLegacyURL>
              <UpdateMetaDescription />
              <UpdateDocumentTitle>
                <ScrollToTopRedirect>
                  <PairDeviceContainer {...this.props} />
                </ScrollToTopRedirect>
              </UpdateDocumentTitle>
              <RouteWithReactHelmet />
            </RedirectLegacyURL>
          );
        }
        break;
      case '/campaign':
        return (
          <RedirectLegacyURL>
            <UpdateMetaDescription />
            <UpdateDocumentTitle>
              <ScrollToTopRedirect>
                <Voucher showHeader={true} {...this.props} />
              </ScrollToTopRedirect>
            </UpdateDocumentTitle>
            <RouteWithReactHelmet />
          </RedirectLegacyURL>
        );
      case '/gethelp':
        return (
          <RedirectLegacyURL>
            <UpdateMetaDescription />
            <UpdateDocumentTitle>
              <ScrollToTopRedirect>
                <GetHelp {...this.props} />
              </ScrollToTopRedirect>
            </UpdateDocumentTitle>
            <RouteWithReactHelmet />
          </RedirectLegacyURL>
        );
      case '/advertise':
        return (
          <RedirectLegacyURL>
            <UpdateMetaDescription />
            <UpdateDocumentTitle>
              <ScrollToTopRedirect>
                <Advertise {...this.props} />
              </ScrollToTopRedirect>
            </UpdateDocumentTitle>
            <RouteWithReactHelmet />
          </RedirectLegacyURL>
        );
      case '/venues':
        return (
          <RedirectLegacyURL>
            <UpdateMetaDescription />
            <UpdateDocumentTitle>
              <ScrollToTopRedirect>
                <CommercialVenues {...this.props} />
              </ScrollToTopRedirect>
            </UpdateDocumentTitle>
            <RouteWithReactHelmet />
          </RedirectLegacyURL>
        );
      case '/fitnessonoptussport':
        return (
          <RedirectLegacyURL>
            <UpdateMetaDescription />
            <UpdateDocumentTitle>
              <ScrollToTopRedirect>
                <FitnessLanding {...this.props} />
              </ScrollToTopRedirect>
            </UpdateDocumentTitle>
            <RouteWithReactHelmet />
          </RedirectLegacyURL>
        );
      case '/terms':
        window.open('/optus-sport-terms-and-conditions-230424.pdf', '_self');
        return;
      case '/samsung/terms':
        window.open(`../vendor/samsung/terms.pdf`, '_self');
        return;
      case '/epg':
        return (
          <RedirectLegacyURL>
            <ScrollToTopRedirect>
              <Epg {...this.props} />
            </ScrollToTopRedirect>
          </RedirectLegacyURL>
        );
      case '/samsungtv':
        return (
          <RedirectLegacyURL>
            <ScrollToTopRedirect>
              <SamsungTV {...this.props} />
            </ScrollToTopRedirect>
          </RedirectLegacyURL>
        );
      default:
      // Do nothing just continue
    }

    // Custom behaviour for articles
    if (isArticlePage) {
      // Check if we need to just output the article content on its own
      // as mobile apps require no header/footer
      if (setDevice === 'mobile') {
        const articlesRoute = routes.find(
          (item) => item.name === 'NewsArticleDetail',
        );
        const ArticlesRouteComponent = _.get(articlesRoute, 'component', null);
        const articlesRoutePath = _.get(articlesRoute, 'path', null);
        const articlesRoutePathPrimal = articlesRoutePath.split('/news').pop();
        const articlesRenderFuncWithProps = (props) => {
          const articlesRouteParams = _.get(articlesRoute, 'params', {});

          return (
            <>
              <ArticlesRouteComponent
                setDevice={setDevice}
                {...props}
                {...articlesRouteParams}
              />
            </>
          );
        };

        return ArticlesRouteComponent && articlesRoutePath ? (
          <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
            <RedirectLegacyURL>
              <UpdateMetaDescription />
              <UpdateDocumentTitle>
                <ScrollToTopRedirect>
                  <Switch>
                    <Route
                      exact
                      path={`/news${articlesRoutePathPrimal}`}
                      render={articlesRenderFuncWithProps}
                    />
                  </Switch>
                </ScrollToTopRedirect>
              </UpdateDocumentTitle>
              <ChartBeatContainer />
              <RouteWithGoogleAnalytics />
            </RedirectLegacyURL>
          </BrowserRouter>
        ) : null;
      }
    }

    const shouldShowSaagSpace =
      !this.state.hideSaagBar &&
      this.props.content.saag.visibility &&
      defaultConfig.saagEnabled;

    const { state: { isShowSearchSectionInNavbar } = {} } = this.context;

    return (
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <RedirectLegacyURL>
          <UpdateMetaDescription />
          <UpdateDocumentTitle useOwnMetadata={this.state.useOwnMetadata}>
            <ScrollToTopRedirect>
              <div className='absolute left-0 top-0 z-99999 w-full'>
                <NotificationStatusBar />
              </div>
              {!this.state.useOwnPageHeader && (
                <PageHeader
                  loginPath={loginPath}
                  overrideShowNotification={this.state.showNotification}
                  hideSaagBar={
                    this.state.hideSaagBar ||
                    !this.props.content.saag.visibility
                  }
                  className={`${
                    _.toLower(browserName) !== 'edge' &&
                    this.state.showHelpSupport
                      ? 'blur-element '
                      : ''
                  }`}
                />
              )}
              <div
                className={`
                  page-wrapper
                  min-h-page
                  pt-0
                  overflow-hidden
                  relative
                  ${isArticlePage ? 'os-transition' : null}
                  ${
                    isFitnessSectionEnabled()
                      ? theme && isArticlePage
                        ? `bg-${theme.bgColor}`
                        : 'bg-white'
                      : ''
                  }
                  ${
                    !this.state.useOwnPageHeader
                      ? shouldShowSaagSpace
                        ? 'saag-enabled'
                        : 'saag-disabled'
                      : 'page-own-header'
                  }
                  ${
                    this.state.showHelpSupport || isShowSearchSectionInNavbar
                      ? 'blur-element pointer-events-none'
                      : ''
                  }
                `}
              >
                <Switch>
                  {routes.map((route, index) => {
                    const RouteComponent = _.get(route, 'component', null);
                    const routePath = _.get(route, 'path', null);
                    const routeParams = {
                      ..._.get(route, 'params', {}),
                      hideSaagBar: _.get(route, 'hideSaagBar') || false,
                    };
                    const routeKey = _.get(route, 'key', '');
                    const customRenderFuncWithProps = (props) => {
                      return (
                        <RouteWrapper
                          route={route}
                          setState={this.setState.bind(this)}
                          onPathnameChange={this.props.onPathnameChange}
                          navigations={this.props.navigations}
                          isNavigationsReady={isNavigationsReady}
                        >
                          <RouteComponent {...routeParams} {...props} />
                        </RouteWrapper>
                      );
                    };

                    if (RouteComponent && routePath) {
                      return (
                        <Route
                          exact={route.exact || true}
                          key={routeKey}
                          path={routePath}
                          params={route.params || null}
                          render={customRenderFuncWithProps}
                        />
                      );
                    }

                    return null;
                  })}
                </Switch>
                <HelpSupportButton
                  onClick={(e) =>
                    this.setState({
                      showHelpSupport: true,
                      showNotification: false,
                    })
                  }
                  absolute={this.state.isFooterVisible}
                />
              </div>
              <HelpSupportUI
                showModal={this.state.showHelpSupport}
                closeModal={() => this.setState({ showHelpSupport: false })}
                showNotification={() =>
                  this.setState({ showNotification: true })
                }
                user={user}
                newHome={newHome}
              />
              <Helper />
              {pageFooter}
            </ScrollToTopRedirect>
          </UpdateDocumentTitle>
          <RouteWithReactHelmet />
          <RouteWithGoogleAnalytics />
          <ChartBeatContainer />
        </RedirectLegacyURL>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  const navigations = _.get(state, 'navigation.navigations', []);
  const { user, content } = state;
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return {
    navigations: navigations,
    user: user,
    newHome: Number(getConfigValue(query, state, 'homepageVersion', 1)) === 2,
    content: content,
    theme: _.get(state, ['themeConfig', 'currentTheme']),
  };
}

export default withRouter(
  connect(mapStateToProps)(withFeatureFlags(PortalRoutes)),
);
